<template>
    <div class="vessel">
        <div class="title">{{ title }}</div>
        <div class="sub-title">{{ subTitle }}</div>
    </div>
</template>
<script>
export default({
    name: 'card',
    props: {
        title: {
            type: String,
            default: "默认标题"
        },
        subTitle: {
            type: String,
            default: '副标题'
        }
    },
    data() {
        return {
        }
    }
})
</script>
<style lang="scss" scoped>
.vessel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 110px;
    background: #1d2132;
    border-radius: 5px;
    &:hover{
        background-color: #1c202c;
    }
    .title{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 15px;
    }
    .sub-title{
        width: 210px;
        height: 31px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 0.5;
        text-align: center;
    }
}
</style>