<template>
    <div class="vessel">
        <div class="tab">
            <div class="tabItem" 
                :class="{activeTabItem: item.id == activeTab}"
                v-for="item in options" :key="item.id" 
                @click="tabClick(item.id)">
              {{ item.name }}
            </div>
        </div>
    </div>
</template>
<script>
export default({
    name: 'tab',
    props: {
        options: {
            type: Array,
            default: ()=>[]
        },
        actived: {
            type: Number,
            default: 1
        }
    },
    watch: {
        actived(newVal) {
            this.activeTab = newVal
        }
    },
    data() {
        return {
            activeTab: 1
        }
    },
    mounted() {
        
    },
    methods: {
        tabClick(val){
            this.activeTab = val
            this.$emit('click', val)
        }
    }
})
</script>
<style lang="scss" scoped>
.vessel{
    color: #fff;
    .tab{
        display: flex;
        width: calc(100% - 80px);
        height: 60px;
        padding: 0 40px;
        border-bottom: 1px solid #2F374C;
        .tabItem{
            width: 120px;
            text-align: center;
            line-height: 65px;
            color: #B2C1E4;
            cursor: pointer;
            &:hover{
                color: #fff;
            }
        }
        .activeTabItem{
            background: linear-gradient(90deg, #F030A5 0%, #7737DB 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;

            border-bottom: 4px solid;
            border-image: linear-gradient(to right, #F030A5, #7737DB) 1;
        }

    }
}
</style>