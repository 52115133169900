<template>
  <div class="chatHome">
    <div class="chatLeft">
      <div class="online-person">
        <div class="person-cards-wrapper">
          <div class="addNew" @click="addChat">
            <i class="el-icon-plus"/>&nbsp;&nbsp;新会话
          </div>
          <PersonCard 
            v-show="addNewChat" 
            :edit-status="editStatus" 
            :chatInfo="newChatInfo"
            :pcCurrent="0"
            @close="closeAdd"
            @updateTitle="createNewChat"></PersonCard>
          <div
            class="personList"
            v-for="item in chatList"
            :key="item.id"
            @click="clickPerson(item)"
          >
            <PersonCard
              :chatInfo="item"
              :pcCurrent="pcCurrent"
              @updateTitle="updateChat"
              @deleItem="deleChat"
            ></PersonCard>
          </div>
        </div>
      </div>
    </div>
    <div class="chatRight">
      <div>
        <ChatWindow
          :show="showChatWindow"
          :activedChat="chatWindowInfo"
          @personCardSort="personCardSort"
        ></ChatWindow>
      </div>
      <!-- <div class="showIcon" v-else> -->
        <!-- <span class="iconfont icon-snapchat"></span> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import PersonCard from "@/components/PersonCard.vue";
import ChatWindow from "./chatwindow.vue";
import {createChat, getChatList, deleChat, updateChat} from "@/api/chatHome"
import eventbus from "@/util/eventbus";
export default {
  name: "App",
  components: {
    PersonCard,
    ChatWindow,
  },
  data() {
    return {
      pcCurrent: "",
      chatList: [],
      showChatWindow: false,
      editStatus: false,
      addNewChat: false,
      chatWindowInfo: {},
      selectSence: '',
      newChatInfo: {
        id: 0,
        title: '新会话',
        prompt_id: '',
        chat_id: ''
      }
    };
  },
  mounted() {
    eventbus.$on("clickScene", (data)=>{
      this.selectSence = data
    })
    this.getChatList()
  },
  methods: {
    // 重置新增表单
    resetNewChatInfo(){
      this.newChatInfo = {
        id: 0,
        title: '新会话',
        prompt_id: '',
        chat_id: ''
      }
    },
    // 新增会话按钮操作
    addChat() {
      this.addNewChat = true
      this.editStatus = true
      this.showChatWindow = false
      this.pcCurrent = 0
      this.chatWindowInfo = this.newChatInfo
      this.$message({
        message: "请选择对话情景，没选则默认为自由对话！",
        type: 'warning',
        duration: 5000
      })
    },
    // 新增会话确认提交
    async createNewChat(val){
      this.newChatInfo.prompt_id = this.selectSence? this.selectSence.id : ' '
      this.newChatInfo.title = val.title
      await createChat(this.newChatInfo)
      await this.getChatList()
      this.$message.success("新增成功！")
      this.pcCurrent = this.chatList[0].id
      this.chatWindowInfo = this.chatList[0]
      this.showChatWindow = true
      this.selectSence = ''
      this.addNewChat = false
      this.editStatus = false   
      this.resetNewChatInfo()   
    },
    // 关闭新增
    closeAdd() {
      this.addNewChat = false
      this.editStatus = false
    },
    async getChatList() {
      const res = await getChatList({})
      this.chatList = res.data
    },
    clickPerson(info) {
      this.showChatWindow = true;
      this.chatWindowInfo = info;
      this.pcCurrent = info.id;
      this.addNewChat = false
    },
    // 修改会话标题
    async updateChat(query) {
      await updateChat(query)
      this.$message.success('标题修改成功！')
      this.getChatList()
    },
    // 删除会话
    async deleChat(val) {
      await deleChat({id: val.id})
      this.$message.success("删除会话成功！")
      this.showChatWindow = false
      this.getChatList()
    },
    personCardSort(id) {
      console.log(id,'传过来了')
      // if (id !== this.personList[0].id) {
      //   console.log(id);
      //   let nowPersonInfo;
      //   for (let i = 0; i < this.personList.length; i++) {
      //     if (this.personList[i].id == id) {
      //       nowPersonInfo = this.personList[i];
      //       this.personList.splice(i, 1);
      //       break;
      //     }
      //   }
      //   this.personList.unshift(nowPersonInfo);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.chatHome {
  // margin-top: 20px;
  display: flex;
  .chatLeft {
    width: 290px;
    height: calc(100vh - 80px);
    background: #1B202C;
    .online-person {
      .person-cards-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        overflow: hidden;
        overflow-y: scroll;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          width: 0; /* Safari,Chrome 隐藏滚动条 */
          height: 0; /* Safari,Chrome 隐藏滚动条 */
          display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
        }
        .addNew{
          width: 260px;
          height: 40px;
          background: rgba(197,213,251,0.4);
          border: 1px dashed #C5D5FB;
          border-radius: 5px;
          margin-bottom: 20px;
          color: #fff;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
          &:hover{
            background-color: #768097;
          }
        }
      }
    }
  }

  .chatRight {
    flex: 1;
    .showIcon {
      position: absolute;
      top: calc(50% - 150px); /*垂直居中 */
      left: calc(50% - 50px); /*水平居中 */
      .icon-snapchat {
        width: 300px;
        height: 300px;
        font-size: 300px;
        // color: rgb(28, 30, 44);
      }
    }
  }
}
</style>
