import request from "@/util/request";

export function getPriceList(params) {
    return request({
        url: '/v1/price',
        method: 'get',
        params
    })
}

export function getQrcode(data) {
    return request({
        url: "/v1/order/store",
        method: 'post',
        data
    })
}