<template>
    <div class="vessel">
        <el-dialog width="400px" :visible.sync="ifShow" @close="closeDialog">
            <!--账号登录、短信登录层-->
            <div class="login-layer" v-show="!ifRegister && !forgetPass">
                <myTab class="my-tab" :options="tableList" :actived="showTab" @click="selectTab"></myTab>
                <div class="login-tab">
                    <div class="register-tip" v-if="true">验证即登录，未注册将自动创建帐号</div>
                    <el-form :model="loginFrom" ref="loginFrom" label-width="40px">
                        <el-form-item prop="mobile">
                            <el-input class="input-width" v-model="loginFrom.mobile" placeholder="输入手机号" @blur="getPhone(loginFrom.mobile)"></el-input>
                        </el-form-item>
                        <el-form-item prop="password" v-if="showTab == 1">
                            <el-input class="input-width" type="password" v-model="loginFrom.password" show-password placeholder="输入密码"></el-input>
                        </el-form-item>
                        <el-form-item prop="code" v-if="showTab == 2">
                            <el-col :span="13">
                                <el-input v-model="loginFrom.code" placeholder="输入验证码"></el-input>
                            </el-col>
                            <el-col :span="9" style="margin-left:8px">
                                <myButton 
                                    v-if="!counting"
                                    :width="120" 
                                    :height="40" 
                                    :type="'primary'" 
                                    :round="false" 
                                    :disabled="sendVerifyBtnDisabled" 
                                    @click.native="sendVerify">
                                    {{ verifyText }}
                                </myButton>
                                <myButton 
                                    v-if="counting"
                                    :width="120" 
                                    :height="40" 
                                    :type="'primary'" 
                                    :round="false" 
                                    :disabled="true" >
                                    {{ "重新发送(" + countDown + "s)" }}
                                </myButton>
                            </el-col>
                        </el-form-item>
                    </el-form>
                    <div class="rules">{{ loginTip }}</div>
                    <div class="submit">
                        <div class="forget" v-show="showTab == 1" @click="switchForget">忘记密码？</div>
                        <div style="margin: 10px 40px 10px 0" v-show="showTab == 2">&nbsp;</div><!--占位-->
                        <myButton :width="320" :height="50" :text="'登录'" :type="'primary'" :round="false" :disabled="!(loginFrom.mobile&&(loginFrom.password||loginFrom.code))" @click.native="login"></myButton>
                        <div class="bottom-tip">
                            <span>还没有账号？</span>
                            <span class="register" @click="switchLayer(1)">立即注册</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--注册层-->
            <div class="register-layer" v-show="ifRegister && !forgetPass">
                <div class="title" style="color: #fff">欢迎注册</div>
                <div class="subForm">
                    <el-form :model="registerFrom" ref="registerFrom" label-width="40px">
                        <el-form-item prop="mobile">
                            <el-input class="input-width" v-model="registerFrom.mobile" placeholder="输入手机号" @input="verifyPhone(registerFrom.mobile)"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-popover
                                placement="top"
                                width="280"
                                trigger="click">
                                <div>
                                    <div>长度为8~14个字符</div>
                                    <div>字母、数字以及标点符号至少包含2种</div>
                                    <div>不允许有空格、中文</div>
                                </div>
                                <el-input class="input-width" slot="reference" type="password" v-model="registerFrom.password" show-password placeholder="输入密码" @input="verifyPassword(registerFrom.password)"></el-input>
                            </el-popover>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-col :span="13">
                                <el-input v-model="registerFrom.code" placeholder="输入验证码"></el-input>
                            </el-col>
                            <el-col :span="9" style="margin-left:8px">
                                <myButton 
                                    v-if="!counting"
                                    :width="120" 
                                    :height="40" 
                                    :type="'primary'" 
                                    :round="false" 
                                    :disabled="!(registerFrom.mobile && registerFrom.password)" 
                                    @click.native="sendVerify">{{ verifyText }}</myButton>
                                    <myButton 
                                    v-if="counting"
                                    :width="120" 
                                    :height="40" 
                                    :type="'primary'" 
                                    :round="false" 
                                    :disabled="true" >
                                    {{ "重新发送(" + countDown + "s)" }}
                                </myButton>
                            </el-col>
                        </el-form-item>
                    </el-form>
                    <div class="rules">{{ registerTip }}</div>
                    <div class="submit">
                        <myButton 
                            class="register-btn" 
                            :width="320" 
                            :height="50" 
                            :text="'注册'" 
                            :type="'primary'" 
                            :round="false" 
                            :disabled="!(ifPhone&&ifPassword&&(registerFrom.code))"
                            @click.native="register"></myButton>
                        <div class="bottom-tip">
                            <span>已有账号？</span>
                            <span class="login" @click="switchLayer(2)">立即登录</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--忘记密码层-->
            <div class="forget-layer" v-show="forgetPass">
                <div class="title">账号验证</div>
                <div class="verifyForm">
                    <el-form :model="forgetFrom" ref="forgetFrom" label-width="40px" v-show="forgetVerfity">
                        <el-form-item prop="mobile">
                            <el-input class="input-width" v-model="forgetFrom.mobile" placeholder="输入手机号" @blur="getPhone(forgetFrom.mobile)"></el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-col :span="13">
                                <el-input v-model="forgetFrom.code" placeholder="输入验证码"></el-input>
                            </el-col>
                            <el-col :span="9" style="margin-left:8px">
                                <myButton 
                                    v-if="!counting"
                                    :width="120" 
                                    :height="40" 
                                    :type="'primary'" 
                                    :round="false" 
                                    :disabled="sendVerifyBtnDisabled" 
                                    @click.native="sendVerify">{{ verifyText }}</myButton>
                                <myButton 
                                    v-if="counting"
                                    :width="120" 
                                    :height="40" 
                                    :type="'primary'" 
                                    :round="false" 
                                    :disabled="true" >
                                    {{ "重新发送(" + countDown + "s)" }}
                                </myButton>
                            </el-col>
                        </el-form-item>
                    </el-form>
                    <el-form :model="forgetFrom" ref="forgetFrom" label-width="40px" v-show="!forgetVerfity">
                        <el-form-item prop="password">
                            <el-popover
                                placement="top"
                                width="280"
                                trigger="click">
                                <div>
                                    <div>长度为8~14个字符</div>
                                    <div>字母、数字以及标点符号至少包含2种</div>
                                    <div>不允许有空格、中文</div>
                                </div>
                                <el-input class="input-width" slot="reference" type="password" v-model="forgetFrom.password" show-password placeholder="输入密码"></el-input>
                            </el-popover>
                        </el-form-item>
                        <el-form-item prop="password" v-if="showTab == 1">
                            <el-input class="input-width" type="password" v-model="forgetFrom.passwordAgin" show-password placeholder="输入密码" @blur="checkPassword"></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="rules">{{ forgetTip }}</div>
                    <div class="submit">
                        <myButton 
                            v-if="forgetVerfity"
                            class="register-btn" 
                            :width="320" 
                            :height="50" 
                            :text="'下一步'" 
                            :type="'primary'" 
                            :round="false" 
                            :disabled="!(forgetFrom.mobile&&forgetFrom.code)"
                            @click.native="nextStep"></myButton>
                        <myButton 
                            v-if="!forgetVerfity"
                            class="register-btn" 
                            :width="320" 
                            :height="50" 
                            :text="'确定'" 
                            :type="'primary'" 
                            :round="false" 
                            :disabled="!ifEqual"
                            @click.native="confirm"></myButton>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
const passwordRules = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{8,14}$/
const phoneRules = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/

import storage from 'store'
import { mapActions } from 'vuex'
import { getVerify, checkCode, confirmPassword } from '@/api/login'
import myTab from '@/components/tab.vue'
import myButton from '@/components/myButton.vue'
export default({
    name: 'login',
    components:{myTab, myButton},
    props: {
        show: {
            type: Boolean,
            defalut: false
        }
    },
    watch: {
        show(newVal){
            this.ifShow = newVal    //避免改变父组件传过来的prop值
        }
    },
    data() {
        return {
            ifShow: this.show,   //避免改变父组件传过来的prop值
            showTab: 1, // 初始化展示账号登录
            sendVerifyBtnDisabled: true,
            verifyText: '发送验证码',
            countDown: 60, // 倒计时初始值
            counting: false, // 计时器是否正在运行
            ifRegister: false,
            forgetPass: false,
            ifPhone: false, // 验证手机号
            ifPassword: false,
            forgetVerfity: true,
            ifEqual: false,
            tableList: [
                {id: 1, name: '账号登录'},
                {id: 2, name: '短信登录'}
            ],
            loginTip: '',
            registerTip: '',
            forgetTip: '',
            loginFrom: {
                mobile: '',
                password: ''
            },
            registerFrom: {
                mobile: '',
                password: '',
                code: ''
            },
            forgetFrom: {}
        }
    },
    methods: {
        resetLoginFrom() {
            this.loginFrom = {}
            this.registerFrom = {
                mobile: '',
                password: '',
                code: ''
            }
        },
        // 关闭弹出层
        closeDialog(){
            this.showTab = 1;
            this.resetLoginFrom()
            this.$emit('close', this.ifShow)
            this.ifRegister = false
            this.forgetPass = false
        },
        // 切换登录类型
        selectTab(item){
            this.showTab = item
            this.resetLoginFrom()
            this.sendVerifyBtnDisabled = true
        },
        // 登录、注册切换
        switchLayer(val) {
            console.log(val,'切换了');
            if(val === 1){
                this.ifRegister = true
                this.loginFrom = {}
            }else if(val === 2){
                this.ifRegister = false
                this.registerFrom = {}
            }
        },
        // 验证手机号
        verifyPhone(phone) {
            if(!phoneRules.test(phone)){
                this.registerTip = "手机号格式不正确"
                this.ifPhone = false
            }else{
                this.registerTip = ''
                this.ifPhone = true
            }
        },
        // 验证注册密码
        verifyPassword(password) {
            if(!passwordRules.test(password)){
                this.registerTip = "密码不符合要求"
                this.ifPassword = false
            }else{
                this.registerTip = ''
                this.ifPassword = true
            }
        },
        // 获取手机号
        getPhone(val){
            if(!phoneRules.test(val)){
                this.loginTip = "手机号格式不正确"
                this.forgetTip = "手机号格式不正确"
            }else{
                this.sendVerifyBtnDisabled = false
                this.loginTip = ""
                this.forgetTip = ""
            }
        },
        
        // 发送验证码
        async sendVerify() {
            this.startCountDown();
            const mobile = this.loginFrom.mobile || this.registerFrom.mobile || this.forgetFrom.mobile
            await getVerify({mobile: mobile})
        },
        
        startCountDown() {
            this.counting = true; // 设置计时器状态为运行中
            this.sendVerifyBtnDisabled = true;
            const timer = setInterval(() => {
                if (this.countDown > 0) {
                this.countDown--; // 更新倒计时剩余时间
                } else {
                clearInterval(timer); // 清除计时器
                this.counting = false;
                this.sendVerifyBtnDisabled = false; // 设置计时器状态为停止
                this.countDown = 60; // 重置倒计时时间
                }
            }, 1000);
        },
        // 切换到忘记密码
        switchForget() {
            this.resetLoginFrom()
            this.forgetPass = true
        },
        // 忘记密码下一步操作
        nextStep() {
            checkCode(this.forgetFrom).then(res =>{
                this.forgetVerfity = false
            }).catch(error=>{
                this.$message({
                    message: error,
                    type: 'error'
                })
            })
        },
        // 密码是否一致
        checkPassword() {
            if(!passwordRules.test(this.forgetFrom.password)){
                this.forgetTip = "密码不符合要求"
                this.ifEqual = false
            }else{
                if(this.forgetFrom.password === this.forgetFrom.passwordAgin){
                    console.log(this.ifEqual,'相等');
                    this.ifEqual = true
                    this.forgetTip = ""
                }else{
                    this.forgetTip = "两次密码输入不一致"
                    this.ifEqual = false
                }
            }
        },
        // 确认密码
        confirm() {
            console.log(this.forgetFrom,'去去去');
            confirmPassword(this.forgetFrom).then(res=>{
                this.ifShow = false
                this.$message.success("密码修改成功，请重新登录！")
            }).catch(error=>{
                this.forgetTip = error
            })
        },
        ...mapActions(['Login', 'NoteLogin', 'PhoneRegister']),
        // 登录
        login() {
            if(this.showTab === 1){ //手机号登录
                this.Login(this.loginFrom).then(res=>{
                    this.ifShow = false
                    location.reload()
                }).catch(error=>{
                    this.loginTip = error
                })
            }else if(this.showTab === 2){ // 短信登录
                this.NoteLogin(this.loginFrom).then(res=>{
                    console.log(res,'短信登录')
                    this.ifShow = false
                    location.reload()
                }).catch(error=>{
                    this.loginTip = error
                })
            }
        },
        // 注册
        register() {
            this.PhoneRegister(this.registerFrom).then(res=>{
                this.ifShow = false
                // location.reload()
                this.$message.success("注册成功，请登录！")
            }).catch(error=>{
                this.registerTip = error
            })
        }
        
    }
})
</script>
<style lang="scss" scoped>
.vessel{
    ::v-deep .el-dialog__body,
    ::v-deep .el-dialog__header{
        background-color: #111322;
        padding: 0;
    }
    ::v-deep .el-dialog__headerbtn .el-dialog__close{
        color: #fff;
        font-size: 24px;
    }
    .login-layer{
        .my-tab{
            padding-top: 10px;
        }
        .login-tab{
            margin-top: 40px;
            ::v-deep .el-form-item:last-of-type{
                margin: 0;
            }
            .register-tip{
                position: relative;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #B2C1E4;
                bottom: 15px;
                left: 40px;
            }
            .rules{
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FF0000;
                margin-left: 40px;
                margin-top: 10px;
            }
            .submit{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #B2C1E4;
                .forget{
                    align-self: flex-end;
                    margin: 10px 40px 10px 0;
                    &:hover{
                        color: #fff;
                    }
                }
                .bottom-tip{
                    margin: 20px 0 33px;
                    .register{
                        text-decoration: underline;
                        cursor: pointer;
                        &:hover{
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    .register-layer{
        .title{
            width: 100%;
            height: 64px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
            line-height: 64px;
            border-bottom: 1px solid #2F374C;
        }
        .subForm{
            margin-top: 40px;
            ::v-deep .el-form-item:last-of-type{
                margin: 0;
            }
            .register-tip{
                position: relative;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #B2C1E4;
                bottom: 15px;
                left: 40px;
            }
            .rules{
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FF0000;
                margin-left: 40px;
                margin-top: 10px;
            }
            .submit{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #B2C1E4;
                .register-btn{
                    margin-top: 36px;
                }
                .bottom-tip{
                    margin: 20px 0 33px;

                    .login{
                        text-decoration: underline;
                        cursor: pointer;
                        &:hover{
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    .forget-layer{
        .title{
            width: 100%;
            height: 64px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
            line-height: 64px;
            border-bottom: 1px solid #2F374C;
        }
        .verifyForm{
            margin-top: 40px;
            ::v-deep .el-form-item:last-of-type{
                margin: 0;
            }
            .register-tip{
                position: relative;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #B2C1E4;
                bottom: 15px;
                left: 40px;
            }
            .rules{
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FF0000;
                margin-left: 40px;
                margin-top: 10px;
            }
            .submit{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #B2C1E4;
                .register-btn{
                    margin-top: 36px;
                    margin-bottom: 56px;
                }

            }
        }
    }
    .input-width{
        width: 320px;
    }
    ::v-deep .el-input__inner{
        color: #fff;
        background: #2E364A;
        border-radius: 5px;
        border: none;
        &:focus{
            border: 1px solid #FFFFFF;
        }
    }
    ::v-deep .el-popper{
        background: #000 !important;
        color: #fff;
    }
}
</style>