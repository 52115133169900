<template>
    <div class="vessel">
        <div class="account">
            <div class="margin">
                <div class="name">账号：{{ userInfo.username }}</div>
                <div class="member">会员：<span class="member-time">{{ userInfo.tip }}</span></div>  
            </div>
            <div class="exit" @click="logOut">退出登录</div>
        </div>
        <div class="package" v-if="isShowPay">
            <div :class="[package.id === i.id? 'options' : 'dark']" v-for="i in priceList" :key="i.id" @click="selectPricePackage(i)">
                <div class="tip" v-if="i.recommend">{{ i.recommend }}</div>
                <div class="type">{{ i.title }}</div>
                <div class="price"><span class="money">￥<span>{{ i.price }}</span></span></div>
                <div class="price-by-day">{{ i.description }}</div>
            </div>
        </div>
        <div class="pay" v-if="isShowPay">
            <div class="qrcode">
                <!-- <img src="../../assets/qrcode.png" width="150" height="150"/> -->
                <div id="payQRcode" ref="payQRcode"></div>
            </div>
            <div class="pay-price">
                <div class="amount">支付金额：<span class="money">￥<span>{{ package.price }}</span></span></div>
                <div class="bottom-tip">
                    <img src="../../assets/weixin.png" width="30" height="30"/>
                    <img src="../../assets/zhifubao.png" width="30" height="30"/>
                    <span>请使用微信或支付宝扫码支付</span>
                </div>
            </div>
            <div class="carousel">
                <div class="item" v-for="i in 4">1***3开通了包年会员</div>
            </div>
        </div>
    </div>

</template>
<script>
import { getPriceList, getQrcode } from "@/api/shop"
import eventbus from "@/util/eventbus"
import QRCode from 'qrcodejs2';

export default({
    name: 'shop',
    data() {
        return{
            isShowPay:false,
            priceList: [],
            package: {},
            userInfo: {},
        }
    },
    mounted() {
        this.$store.dispatch('GetInfo').then(res=>{
            this.userInfo = res.data

            if(this.userInfo.level == 0)
        {
            this.isShowPay=true
            this.getPriceList()
            this.getCode()
        }
        })
        
       
    },
    methods: {
        //获取价格列表
        getPriceList() {
            getPriceList({}).then(res=>{
                console.log(res,'价格列表')
                this.priceList = res.data
                this.package = res.data[0]
                this.getImgUrl(res.data[0].id)
            })
        },
        // 选择套餐
        selectPricePackage(val){
            console.log(val,'选择的套餐');
            this.package = val
            this.getImgUrl(val.id)
        },
        // 获取支付二维码链接
        getImgUrl(val) {
            getQrcode({priceId: val}).then(res=>{
                this.getCode(res.data.url)
            })
        },
        getCode(url){
            this.clearQRCode()
            var myQRCode =  new QRCode('payQRcode', {
                    text: url,
                    width: 150,
                    height: 150,
                })
        },
        clearQRCode() {
            var qrcodeDom = this.$refs.payQRcode;
            qrcodeDom.innerHTML = '';
        },
        logOut() {
            window.localStorage.removeItem("access_token")
            window.localStorage.removeItem("userInfo")
            this.$router.push("/ChatHome")
            eventbus.$emit("logOut")
        },
      
    }
})
</script>
<style lang="scss" scoped>
.vessel{
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .account{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 1020px;
        background: #111322;
        border: 1px solid #2F374C;
        border-radius: 5px;
        margin-top: 40px;

        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FEFEFE;
        position: relative;
        .margin{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 60px;
            margin: 30px;
            .member{
                .member-time{
                    width: 231px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;

                    background: linear-gradient(90deg, #FEDC45 0%, #FB7099 100%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        .exit{
            position: absolute;
            bottom: 20px;
            right: 30px;

            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #B2C1E4;
            cursor: pointer;
            &:hover{
                color: #fff;
            }
        }
    }
    .package{
        display: flex;
        width: 1020px;
        .options{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            width: 240px;
            height: 260px;
            background: #FAF6ED;
            border-radius: 5px;
            color: #333333;
            margin: 47px 20px 30px 0;
            .tip{
                position: absolute;
                left: 0;
                top: -17px;
                width: 120px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                background: #E53916;
                border-radius: 5px 0px 5px 0px;

                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
            }
            .type{
                margin-bottom: 27px;
                font-size: 30px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
            }
            .price{
                margin-bottom: 80px;
                .money{
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    background: linear-gradient(90deg, #F030A5 0%, #7737DB 100%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    span{
                        font-size: 36px;
                        background: linear-gradient(90deg, #F030A5 0%, #7737DB 100%);
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
            .price-by-day{
                width: 240px;
                height: 34px;
                text-align: center;
                line-height: 34px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                background: linear-gradient(90deg, #FEDC45, #FB7099);
                border-radius: 0px 0px 5px 5px;
            }
        }
        .dark{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            width: 240px;
            height: 260px;
            background: #2E364A;
            border-radius: 5px;
            margin: 47px 20px 30px 0;
            .tip{
                position: absolute;
                left: 0;
                top: -17px;
                width: 120px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                background: #E53916;
                border-radius: 5px 0px 5px 0px;

                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
            }
            .type{
                margin-bottom: 27px;
                font-size: 30px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #B2C1E4;
            }
            .price{
                margin-bottom: 80px;
                .money{
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #B2C1E4;
                    span{
                        font-size: 36px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #B2C1E4;
                    }
                }
            }
            .price-by-day{
                width: 240px;
                height: 34px;
                text-align: center;
                line-height: 34px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                border-radius: 0px 0px 5px 5px;
                background: #979ba5;
            }
        }
    }
    .pay{
        display: flex;
        align-items: center;
        width: 1020px;
        height: 200px;
        background: #f1f3f5;
        border-radius: 5px;
        margin-bottom: 100px;
        .qrcode{
            margin: 0 25px 0 32px;
        }
        .pay-price{
            .amount{
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                .money{
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    background: linear-gradient(90deg, #F030A5 0%, #7737DB 100%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    span{
                        font-size: 36px;
                        background: linear-gradient(90deg, #F030A5 0%, #7737DB 100%);
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
            .bottom-tip{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 260px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                margin-top: 20px;
            }
        }
        .carousel{
            margin-left: 220px;
            width: 150px;
            .item{
                width: 150px;
                height: 30px;
                background: #E3E5E8;
                border-radius: 15px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                text-align: center;
                line-height: 30px;
                margin-bottom: 10px;
            }
        }
    }
}
</style>