<template>
  <div class="home">
    <el-container>
      <el-header class="header">
        <div v-if="headerShow" class="header-content">
          <div class="logo">
            <img src="../assets/logo.png"/>
          </div>
          <div class="middle">
            <div class="languageModel">
              <span>大语言模型：</span>
              <select id="select" v-model="languageModel" @change="changModel(languageModel)">
                <option v-for="(item, index) in modelList" :key="index" :value="index">{{ item }}</option>
              </select>
            </div>
          </div>
          <div class="login" v-if="!userInfo">
            <span class="tip">普通会员可以免费试用10次，如需获取更多试用次数，请开通会员</span>
            <myButton :type="'primary'" :text="'登录'" :width="100" :height="34" @click.native="login"/>
          </div>
          <div class="userInfo" @click="toShop" v-else>
            <div class="avatar">
              <img src="../assets//img/head_portrait.jpg"/>
            </div>
            <div class="user">
              <div class="name">{{ userInfo.mobile }}</div>
              <div class="member">{{ userInfo.status===1? userInfo.levelName : "开通会员" }}</div>
            </div>
          </div>
        </div>
        <div v-if="!headerShow" class="last" @click="goBack"><i class="el-icon-arrow-left"/></div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <loginDialog :show="showLogin" @close="closePop"></loginDialog>
  </div>
</template>

<script>

import myButton from "@/components/myButton.vue"
import loginDialog from "@/view/login/index.vue"
import eventbus from "@/util/eventbus";
export default {
  name: "App",
  components: {myButton, loginDialog},
  data() {
    return{
      headerShow: true,
      showLogin: false,
      form: {},
      userInfo: '',
      languageModel: 0,
      modelList: []
    }
  },
  mounted() {
    this.$store.dispatch('GetInfo').then(res=>{
      this.userInfo = res.data
    })
    eventbus.$on("logOut", ()=>{
      this.headerShow = true
      this.userInfo = ''
      this.$message.success("退出登录成功！")
    })
    localStorage.getItem('lang_model')? this.languageModel = localStorage.getItem('lang_model') : ''
    const res = localStorage.getItem('model_list')
    this.modelList = JSON.parse(res)
  },
  methods: {
    login() {
      this.showLogin = true
    },
    toShop() {
      this.$router.push("/shop")
      this.headerShow = false
    },
    goBack() {
      window.history.go(-1);
      this.headerShow = true
    },
    closePop(isshow){  // 必要的关闭，不然子组件收不到关闭的指令，避免二次点击时没反应
      this.showLogin = isshow  // 回传关闭指令给子组件的弹出层
    },
    changModel(model){
      localStorage.setItem('lang_model', model)
    }
  }

};
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  background-color:#111322;
  .header{
    height: 80px !important;
    background: #1B202C;
    box-shadow: 0px -1px 0px 0px #2F374C;
    .header-content{
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 20px;
      .logo{
        margin-left: 30px;
        height: 80px;
        line-height: 80px;
      }
      .middle{
        flex: 1;
        margin-left: 80px;
        // background-color: #B2C1E4;
        .languageModel{
          float: left;
          #select{
            width: 150px;
            height: 32px;
            background-color: #1b202c;
            color: #fff;
            border-radius: 5px;
          }
        }        
      }


      .login{
        display: flex;
        align-items: center;
        height: 80px;
        span{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          opacity: 0.7;
          margin-right: 25px;
        }
      }
      .userInfo{
        align-items: center;
        display: flex;
        width: 190px;
        height: 50px;
        background: #2F374C;
        border-radius: 25px;
        cursor: pointer;
        .avatar{
          width: 50px;
          height: 50px;
          background: #FFFFFF;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            clip-path: circle(50%);
          }
        }
        .user{
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100%;
          margin-left: 10px;
          .name{
            height: 11px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
          }
          .member{
            width: 50px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #B2C1E4;

            background: linear-gradient(90deg, #FEDC45 0%, #FB7099 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            cursor: pointer;
          }
        }
      }
    }
    .last{
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: #b2c1e4;
      text-align: center;
      line-height: 24px;
      color: #fff;
      margin: 28px 0 0 50px;
      cursor: pointer;
    }
  }
}
::v-deep .el-main{
  padding: 0;
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
    /* Safari,Chrome 隐藏滚动条 */
    height: 0;
    /* Safari,Chrome 隐藏滚动条 */
    display: none;
    /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
  }
}
</style>