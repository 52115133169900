<template>
  <div>
    <span v-if="type == 'primary'">
      <el-button class="primary" :style="{'width': width+'px', 'height': height+'px'}" :round="round" :disabled="disabled">
        {{ text }}
        <slot></slot>
      </el-button>
    </span>
    <span class="mybutton" v-else>
      <el-button class="" :style="{'width': width+'px', 'height': height+'px'}" :round="round">
        {{ text }}
        <slot></slot>
      </el-button>
    </span>
  </div>
</template>
<script>
  export default({
    name: 'my-button',
    props: {
      text: {
        type: String,
        default: ""
      },
      width: {
        type: Number,
        default: 120
      },
      height: {
        type: Number,
        default: 36
      },
      type: {
        type: String,
        default: ''
      },
      round: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
    }
  })
</script>
<style lang="scss" scoped>
  .primary{
    color: #fff;
    background: linear-gradient(180deg, #F030A5, #7737DB);
    border: none;
  }
  .primary:hover{
    color: #fff;
    background: linear-gradient(180deg, #7737DB, #F030A5);
  }
  .mybutton{
    ::v-deep .el-button{
      color: #000 !important;
      border: 1px solid #666;
      &:hover{
        background-color: #fff;
      }
    }
  }
  ::v-deep .el-button.is-disabled{
    background-color: #ccc;
    color: #fff;
    &:hover{
      background-color: #ccc;
      color: #fff;
    }
  }
</style>