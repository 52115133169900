import request from '@/util/request'

export function getCategory(params) {
    return request({
        url: "/v1/prompts/category",
        method: 'get',
        params
    })
}

export function getPrompts(params) {
    return request({
        url: "/v1/prompts",
        method: "get",
        params
    })
}

// 添加会话
export function createChat(data) {
    return request({
        url: "/v1/chat/item/store",
        method: 'post',
        data
    })
}

// 得到会话列表
export function getChatList(params) {
    return request({
        url: '/v1/chat/item',
        method: 'get',
        params
    })
}

// 删除会话
export function deleChat(data) {
    return request({
        url: '/v1/chat/item/delete',
        method: 'post',
        data
    })
}

// 修改会话标题
export function updateChat(data) {
    return request({
        url: '/v1/chat/item/update',
        method: 'post',
        data
    })
}