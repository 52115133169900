<template>
  <div class="chat-window">
    <div class="content">
      <!--场景模块-->
      <div v-if="!showDialogue" class="scene">
        <chatModules @clickScene="clickScene"></chatModules>
      </div>
      <!--对话内容-->
      <div v-if="showDialogue" class="chat-content" ref="chatContent">
        <a style="display: flex; justify-content: center" @click="getMoreHistory" v-if="lastPage>1 && historyParams.page<=lastPage">查看更多</a>
        <div class="chat-wrapper" v-for="(item, index) in chatList" :key="item.id">
          <div class="chat-friend" v-if="item.type === 'reply'">
            <div class="chat-text">
              <template v-if="isSend && index == chatList.length - 1">
                <span class="flash_cursor" v-if="awaitRes"></span>
              </template>
              <template v-else><span>{{ item.content }}</span></template>
            </div>
          </div>
          <div class="chat-me" v-else>
            <div class="chat-text">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
      <!--响应按钮-->
      <div class="responseBtn" v-if="false">
        <el-button class="mybutton" round>停止响应</el-button>
      </div>
      <!--底部文案输入区域-->
      <div class="chatInputs">
        <div class="type">{{ scene? scene : ' ' }}</div>
        <div class="editor">
          <el-input 
            type="textarea" 
            :rows="5" 
            placeholder="请输入文案……" 
            v-model="inputMsg" 
            @keydown.enter.native="handleEnterKey" 
            @keyup.ctrl.enter.native="handleKeyDown">
          </el-input>
          <div class="sendBtn">
            <el-button :disabled = "isSend" @click="sendText">
              <i class="iconfont icon-send" style="font-weight: bolder; color: #fff"/>
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { animation } from "@/util/util";
import HeadPortrait from "@/components/HeadPortrait";
import Emoji from "@/components/Emoji";
import FileCard from "@/components/FileCard.vue";
import chatModules from "@/view/chatHome/modules.vue"
import {getHistoryChat, sendChat} from "@/api/chatWindow"
import {getPrompts} from "@/api/chatHome"
import Typed from 'typed.js';
export default {
  components: {
    HeadPortrait,
    Emoji,
    FileCard,
    chatModules,
  },
  props: {
    activedChat: Object,
    default() {
      return {};
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    activedChat(newVal) {
      this.getHistoryMsg();
      if(newVal.chat_id){
        this.isSend = false
        this.scene = this.sceneList.find(function(item) { //获取点击的会话的对话情景
          return item.id === newVal.prompt_id;
        })?.name;
      }else{
        this.isSend = true
      }
    },
    show() {
      this.showDialogue = this.show
    }
  },
  data() {
    return {
      chatList: [],
      inputMsg: "",
      showEmoji: false,
      friendInfo: {},
      srcImgList: [],
      isSend: true,
      showDialogue: false,
      awaitRes: false,
      scene: '',
      sceneList: [],
      historyParams: {
        page: 1
      },
      lastPage: undefined,
    };
  },
  mounted() {
    this.showDialogue = this.show;
    this.getSceneList()

  },
  methods: {
    //获取聊天记录
    getHistoryMsg() {
      if(this.activedChat.id){
        this.historyParams.chat_id = this.activedChat.id
        this.historyParams.page = 1
        getHistoryChat(this.historyParams).then((res) => {
          this.chatList = res.data.data
          this.lastPage = res.data.last_page
          this.scrollBottom();
        });
      }
    },
    // 查看更多历史记录
    getMoreHistory() {
      this.historyParams.chat_id = this.activedChat.id
      this.historyParams.page += 1
      getHistoryChat(this.historyParams).then((res) => {
          this.chatList.unshift(...res.data.data)
        });
    },
    // 获取所有的情景模块数据
    getSceneList() {
      getPrompts({}).then(res=>{
        this.sceneList = res.data.data
        localStorage.setItem('model_list', JSON.stringify(res.data.lang_model))
      })
    },
    // 点击情景模块
    clickScene(val) {
      this.scene = val.name
    },
    //发送信息
    sendMsg(msgList) {
      this.chatList.push(msgList);
      this.scrollBottom();
    },
    //获取窗口高度并滚动至最底层
    scrollBottom() {
      this.$nextTick(() => {
        if(this.$refs.chatContent){
          const scrollDom = this.$refs.chatContent;
          animation(scrollDom, scrollDom.scrollHeight - scrollDom.offsetHeight);          
        }
      });
    },
    //关闭标签框
    clickEmoji() {
      this.showEmoji = !this.showEmoji;
    },
    // 换行
    handleKeyDown(event) {
      if (!event.ctrlKe) {
        event.preventDefault(); // 阻止默认的 Enter 换行行为
        this.inputMsg += '\n'; // 在输入文本后添加换行符
      }
    },
    // 按键快捷发送信息
    handleEnterKey(event) {
      if (event.ctrlKey) {
        return;
      }
      if(this.activedChat.id){
        event.preventDefault(); // 阻止默认的 Enter 换行行为
        this.sendText()
      }else{
        this.$message({type: 'info', message: '请先创建会话'})
      }  

    },  
    //发送文字信息
    sendText() {
      this.showDialogue = true

      if(this.inputMsg) {
        let chatMsg = {
          chat_id: this.activedChat.id,
          content: this.inputMsg,
          type: "prompt", //用户
          prompt_id: this.activedChat.prompt_id,
          lang_model: localStorage.getItem('lang_model')
        }
        this.sendMsg(chatMsg)
        this.inputMsg = ""
        let replyData = {
          content: '',
          type: "reply", //ai
          prompt_id: this.activedChat.prompt_id
        }
        this.isSend = true
        this.awaitRes = true
        this.sendMsg(replyData)

        sendChat(chatMsg).then(res=>{
          if(res.data.content){
            this.isSend = false
            let index = 0
            const text = res.data.content
            const intervalId = setInterval(() => {
              this.chatList[this.chatList.length-1].content = text.slice(0, index++)
              this.scrollBottom()
              if (index > text.length) {
                clearInterval(intervalId)
              }
            }, 50)
          }else{
            this.$message.error('没响应结果！')
            this.awaitRes = false
            this.isSend = false
          }
        })

      }else {
        this.$message({
          message: '消息不能为空哦~',
          type: 'warning'
        })
      }
    },
    //发送表情
    sendEmoji(msg) {
      let chatMsg = {
        headImg: require("@/assets/img/head_portrait.jpg"),
        name: "大毛是小白",
        time: "09：12 AM",
        msg: msg,
        chatType: 1, //信息类型，0文字，1图片
        extend: {
          imgType: 1, //(1表情，2本地图片)
        },
        uid: "1001",
      };
      this.sendMsg(chatMsg);
      this.clickEmoji();
    },
    //发送本地图片
    sendImg(e) {
      let _this = this;
      console.log(e.target.files);
      let chatMsg = {
        headImg: require("@/assets/img/head_portrait.jpg"),
        name: "大毛是小白",
        time: "09：12 AM",
        msg: "",
        chatType: 1, //信息类型，0文字，1图片, 2文件
        extend: {
          imgType: 2, //(1表情，2本地图片)
        },
        uid: "1001",
      };
      let files = e.target.files[0]; //图片文件名
      if (!e || !window.FileReader) return; // 看是否支持FileReader
      let reader = new FileReader();
      reader.readAsDataURL(files); // 关键一步，在这里转换的
      reader.onloadend = function () {
        chatMsg.msg = this.result; //赋值
        _this.srcImgList.push(chatMsg.msg);
      };
      this.sendMsg(chatMsg);
      e.target.files = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.flash_cursor {
  width: 2px;
  height: 20px;
  display: inline-block;
  background: #d6e3f5;
  opacity: 1;
  animation: glow 800ms ease-out infinite alternate;
}

@keyframes glow {
  0% {
    opacity: 1;
  }

  25% {
    opacity: .5;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

.chat-window {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    height: calc(100vh - 80px);
    background-color:#111322;
    // padding: 20px;
    display: flex;
    flex-direction: column;
    .scene{
      width: 100%;
      flex: 1;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
        /* Safari,Chrome 隐藏滚动条 */
        height: 0;
        /* Safari,Chrome 隐藏滚动条 */
        display: none;
        /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      }
    }
    .chat-content {
      width: 100%;
      overflow-y: scroll;
      padding: 20px;
      box-sizing: border-box;
      flex: 1;

      &::-webkit-scrollbar {
        width: 0;
        /* Safari,Chrome 隐藏滚动条 */
        height: 0;
        /* Safari,Chrome 隐藏滚动条 */
        display: none;
        /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      }

      .chat-wrapper {
        position: relative;
        word-break: break-all;

        .chat-friend {
          width: 100%;
          float: left;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .chat-text {
            max-width: 60%;
            padding: 20px;
            border-radius: 10px 10px 10px 0px;
            background-color: #41424e;
            color: #fff;

            &:hover {
              // background-color: rgb(39, 42, 55);
            }

            span{
              white-space: break-spaces;
            }
          }

          .chat-img {
            img {
              width: 100px;
              height: 100px;
            }
          }

          .info-time {
            margin: 10px 0;
            color: #fff;
            font-size: 14px;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              vertical-align: middle;
              margin-right: 10px;
            }

            span:last-child {
              color: rgb(101, 104, 115);
              margin-left: 10px;
              vertical-align: middle;
            }
          }
        }

        .chat-me {
          width: 100%;
          float: right;
          margin-bottom: 20px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          .chat-text {
            float: right;
            max-width: 60%;
            padding: 20px;
            border-radius: 10px 10px 0px 10px;
            background-color: #292b39;
            color: #fff;

            &:hover {
              // background-color: rgb(26, 129, 219);
            }
          }

          .chat-img {
            img {
              max-width: 300px;
              max-height: 200px;
              border-radius: 10px;
            }
          }

          .info-time {
            margin: 10px 0;
            color: #fff;
            font-size: 14px;
            display: flex;
            justify-content: flex-end;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              vertical-align: middle;
              margin-left: 10px;
            }

            span {
              line-height: 30px;
            }

            span:first-child {
              color: rgb(101, 104, 115);
              margin-right: 10px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .responseBtn{
      text-align: center;
      margin-bottom: 25px;
      .mybutton{
        background: linear-gradient(180deg, #F030A5, #7737DB);
        border-radius: 17px;
      }
      ::v-deep .el-button{
        border: none;
        color: #fff;
      }
    }

    .chatInputs {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 200px;
      border-top: 1px solid #2F374C;
      .type{
        align-self: flex-start ;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-bottom: 15px;
        margin-left: 5%;
        margin-top: 32px;

        background: linear-gradient(180deg, #F030A5 0%, #7737DB 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .editor{
        display: flex;
        align-items: center;
        width: 90%;
        height: 120px;
        background: #2E364A;
        border-radius: 5px;
        ::v-deep .el-textarea__inner{
          background-color: #2E364A;
          border: none;
          color: #fff;
          resize: none;
          &::-webkit-scrollbar {
            width: 0; /* Safari,Chrome 隐藏滚动条 */
            height: 0; /* Safari,Chrome 隐藏滚动条 */
          }
        }
        .sendBtn{
          ::v-deep .el-button{
            background-color: transparent;
            border: none;
          }
       
        }
      }
    }
  }
}
</style>