import VueRouter from 'vue-router'

import ChatHome from '../view/chatHome/index.vue'
import Shop from '@/view/shop/index.vue'
import Home from "@/view/home.vue";

export default new VueRouter({
    routes: [
        {
            path: "/",
            redirect: "/ChatHome",
            component: Home,
            children: [
                {
                    path: "/ChatHome",
                    name: "ChatHome",
                    component: ChatHome,
                },
                {
                    path: "/shop",
                    name: 'Shop',
                    component: Shop
                }
            ]
        },
    ]
})
