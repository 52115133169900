import request from '@/util/request'
// 账号登录
export function login(data) {
    return request({
        url: '/v1/login',
        method: 'post',
        data
    })
}

// 用户信息
export function getInfo(data) {
    return request({
        url: '/v1/user',
        method: 'post',
        data
    })
}

// 获取验证码
export function getVerify(data) {
    return request({
        url: "/v1/code",
        method: 'post',
        data
    })
}

// 短信登录
export function smsLogin(data) {
    return request({
        url: '/v1/login/sms',
        method: 'post',
        data
    })
}

// 注册
export function getRegister(data) {
    return request({
        url: '/v1/register',
        method: 'post',
        data
    })
}

// 账号验证检查验证码
export function checkCode(data) {
    return request({
        url: '/code/verify',
        method: 'post',
        data
    })
}

// 修改密码确认
export function confirmPassword(data) {
    return request({
        url: '/v1/forgetPassword',
        method: 'post',
        data
    })
}