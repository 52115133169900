<template>
  <div class="person-card" :class="{ activeCard: chatInfo.id == current }">
    <div class="info">
        <el-tag
          v-for="item in tagsList"
          v-show="item.id === chatInfo.prompt_id"
          size="mini"
          :key="item.id"
          :color="item.color"
          effect="dark">
          {{ item.name }}
        </el-tag>
        <div class="info-detail">
          <div v-if="!edit" class="name">{{ chatInfo.title }}</div>
          <div v-if="edit"><el-input v-model='chatText' size="mini"></el-input></div>
        </div>
        <div class="editItem">
          <div v-if="!edit">
            <i class="el-icon-edit pointer" v-if="chatInfo.id == current" @click="editItem"/>
            <el-popconfirm
              v-if="chatInfo.id == current"
              confirm-button-text='确认'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              title="确定删除此记录？"
              @confirm="deleChat"
            >
              <i class="el-icon-delete pointer" slot="reference" />
            </el-popconfirm>
          </div>
          <div v-if="edit">
            <i class="el-icon-check pointer" @click="updateTitle"></i>
            <i class="el-icon-close pointer" @click="closeEdit"></i>
          </div>

        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chatInfo: {
      default: {
      },
    },
    pcCurrent: {
      default: ''
    },
    editStatus: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  data() {
    return {
      edit: false,
      current: '',
      chatText:'',
      tagsList: [
          { id: '', name: '自由会话', color: '#F030A5'},
          { id: 146, name: '文案润色', color: '#5862B0' },
          { id: 147, name: '文章续写', color: '#E3A188' },
          { id: 148, name: '伪原创改写', color: '#287314' },
          { id: 149, name: '总结内容', color: '#75A3BF' },
          { id: 150, name: '小红书文案', color: '#A6688A' },
          { id: 151, name: '英语翻译或修改', color: '#F2921D' },
          { id: 152, name: '中英互译', color: '#E662C2' },
          { id: 153, name: 'IT编程问题', color: '#733F34' },
          { id: 154, name: '代码释义器', color: '#0378A6' },
          { id: 155, name: '编程辅助', color: '#2256F2' },
          { id: 156, name: '微信小程序', color: '#70732F' },
          { id: 157, name: '法律顾问', color: '#69688C' },
          { id: 158, name: '心理健康顾问', color: '#75C045' },
          { id: 159, name: '职业顾问', color: '#8C7974' },
          { id: 160, name: '数学老师', color: '#18D948' },
          { id: 161, name: '历史学家', color: '#D86335' },
          { id: 162, name: '英语老师', color: '#C7C90D' },
          { id: 163, name: '模拟课堂讨论', color: '#42AE91' },
        ]
    }
  },
  watch: {
    pcCurrent: function() {
      this.isActive()
    },
    editStatus(newVal) {
      this.edit = newVal
      this.chatText = this.chatInfo.title
    }
  },
  methods: {
    isActive() {
      this.current = this.pcCurrent
    },
    // 编辑按钮操作
    editItem() {
      this.edit = true
      this.chatText = this.chatInfo.title
    },
    // 提交更改标题
    updateTitle() {
      this.$emit("updateTitle", {
        title: this.chatText,
        id: this.chatInfo.id
      })
      this.edit = false
    },
    //删除会话
    async deleChat() {
      this.$emit("deleItem", this.chatInfo)
    },
    //关闭编辑
    closeEdit(){
      this.edit = false
      this.$emit("close")
    }
  }
};
</script>

<style lang="scss" scoped>
.pointer{
  cursor: pointer;
}
::v-deep .el-tag--mini{
  border: none;
  width: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.person-card {
  width: 290px;
  height: 50px;
  background: #1b202c;
  position: relative;
  // cursor: pointer;
  margin-bottom: 5px;
  .info {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: flex;
    align-items: center;
    .info-detail {
      margin-left: 8px;
      .name {
        width: 120px;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      ::v-deep .el-input__inner{
        color: #fff;
        background-color: #111322;
        border: 1px solid #FFFFFF;
        border-radius: 5px;
      }
    }
    .editItem{
      width: 25%;
      color: #fff;
      div{
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
  &:hover {
    background-color: #1d90f5;
    background: #111322;
    transition: 0.3s;

    box-shadow: 0px 0px 10px 0px #111322;
    // box-shadow:  0 5px 20px rgba(251, 152, 11, .5);
    .info {
      .info-detail {
        .detail {
          color: #fff;
        }
      }
    }
  }
}
.activeCard {
    // background-color: #1d90f5;
    
    background: #111322;
    transition: 0.3s;
    // box-shadow: 3px 2px 10px 0px rgba(0, 136, 255);
    .info {
      .info-detail {
        .detail {
          color: #fff;
        }
      }
    }
}
</style>