import request from '@/util/request'

export function getHistoryChat(params) {
    return request({
        url: '/v1/chat',
        method: 'get',
        params
    })
}

export function sendChat(data) {
    return request({
        url: "/v1/chat/store",
        method: "post",
        timeout: 0,
        data
    })
}