<template>
    <div class="vessel">
        <myTab :options="tabList" :actived="activedTab" @click="clickTab"></myTab>
        <div class="content">
            <card class="card" 
                v-for="item in list" 
                :key="item.id"
                :title="item.name"
                :sub-title="item.summary"
                @click.native="clickItem(item)"></card>
        </div>
    </div>
</template>
<script>
import myTab from "@/components/tab.vue"
import card from "@/components/card.vue"
import { getCategory, getPrompts } from "@/api/chatHome"
import { values } from "store/storages/all"
import eventbus from '@/util/eventbus'
export default({
    name: 'modules',
    components: {myTab, card},
    data() {
        return {
            tabList: [
                {id: 1, name: '全部'},
                // {id: 2, name: '文案'},
                // {id: 3, name: '翻译'},
                // {id: 4, name: '编程'},
                // {id: 5, name: '职业'},
                // {id: 6, name: '教育'},
            ],
            activedTab: 1,
            query: {
                category_id: ''
            },
            list: []
        }
    },
    mounted() {
        this.getCategoryTab()
        this.getList()
    },
    methods: {
        async getCategoryTab() {
            const { data } = await getCategory({})
            data && data.unshift({id: 1, name: '全部'})
            this.tabList = data
        },
        async getList() {
            const res = await getPrompts(this.query)
            this.list = res.data.data
        },
        // 点击tab栏
        clickTab(tab) {
            if(tab === 1){  // 点击tab为“全部”时，不传id
                this.query.category_id = ''
            }else{
                this.query.category_id = tab
            }
            this.getList()
        },
        // 点击情景模块
        clickItem(item) {
            this.$emit("clickScene", item)
            eventbus.$emit("clickScene", item)
        }
    }
})
</script>
<style lang="scss" scoped>
.vessel{
    color: #fff;
    .content{
        margin: 40px 18px 40px 40px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .card{
            margin-bottom: 30px;
            margin-right: 22px;
            cursor: pointer;
        }
    }
}
</style>